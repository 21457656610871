import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import './padppostcodeCheck.scss';
import {useNavigate, Link} from "react-router-dom";
import axios from "axios";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PilotAreaListPadp from "../PilotAreaList/PilotAreaListPadp";
import ErrorSummary from "../Errors/ErrorSummary";
import ChangePostcode from "../ChangePostcode/ChangePostcodePadp";


// Bring in the API Gateway env variable
const { REACT_APP_GATEWAY_URL } = process.env

type PostcodeInput = {
    postcode: string
}

type SelectPostcode = {
    label: string,
    value: string
}

type PostcodeValues= {
    postcodeInput: PostcodeInput,
    selectInput: SelectPostcode
}

interface PostcodeCheckProps {
    onUpdatePostcode: (newPostcode: string | undefined) => void;
}

// to hold the data to populate the options fot the select
let listAddressList: any[] = []

// so we can pass this about the pages as required
let PassedPostcode: string | undefined;

const PadpPostcodeCheck: React.FC<PostcodeCheckProps> = ({onUpdatePostcode}) => {

    const { register, reset, handleSubmit, formState: { errors } } = useForm<PostcodeValues>()
    const [ showSelect, setShowSelect ] = useState(false)
    const [ hideTextInput, setHideTextInput ] = useState(true)


    // this is to help change the page
    const navigate = useNavigate()



    const redirect = (newPath: string) => {
        navigate(newPath,  { state: { postcodeValue: PassedPostcode } })
    }

    // So we can redirect to the ineligible page when the postcode is split
    const splitIneligible = (event: any) => {
        event.preventDefault()
        return redirect(`/ineligible`)
    }

    const onSubmit: SubmitHandler<PostcodeValues> = data => {

        const formattedPostcode = data.postcodeInput.toString().toLowerCase().trim().replace(/ /g, '');
        // call the api gateway
        const apiUrl = REACT_APP_GATEWAY_URL + "/postcode-check/padp"
        console.log('apiUrl', apiUrl);
        
        PassedPostcode = formattedPostcode.toUpperCase()
        console.log('passed postcode', PassedPostcode);
        

        const apiData = { "Postcode": formattedPostcode }
        
        if (data.postcodeInput && !data.selectInput) {
            axios.post(apiUrl, apiData).then((response) =>{

                // Pull the eligibility response from the payload
                const page = response.data.Eligible

                switch (page) {
                    case "YES":
                        return redirect(`/eligible`)
                    case "NO":
                        return  redirect(`/ineligible`)
                    case "BORDER":
                        return redirect(`/unrecognised-scotland-england`)
                    case "NOTFOUND":
                        return redirect(`/unrecognised`)
                    case "OUTSIDESCOTLAND":
                        return redirect(`/outside-scotland`)
                    case "SPLIT":
                        response.data.AddressList.forEach((address: any)  => {
                            listAddressList.push(
                                `${address.Organisation},${address.BuildingNumber}, ${address.Property}, ${address.Street}, ${address.Town}`
                            )
                        })
                        setShowSelect(true)
                        setHideTextInput(false)
                        break
                }
            }).catch((error) => {
                return redirect(`/unrecognised`)
            })
        } else {
            if (data.postcodeInput && data.selectInput.value !== "empty" ) {
                listAddressList = []
                reset()
                redirect(`/eligible`)
            }
        }


        onUpdatePostcode(PassedPostcode);

    }



    return (
        <>
            <HeaderTitle title={
                <>
                     Postcode checker for<br />
                    Pension Age Disability Payment
                </>
            }/>

            {errors.postcodeInput &&
                <ErrorSummary errorText={'Please enter a valid postcode'} title={`There's a problem`}/>
            }

            {errors.selectInput &&
                <ErrorSummary errorText={'Please select an address'} title={`There's a problem`}/>
            }

            <p>Enter your postcode to check if it's in an area open to applications for Pension Age Disability Payment.</p>

            <p>At the moment this is limited to these areas while we make sure the process works:</p>
            
            <PilotAreaListPadp/>

            <p>Applications will be open across Scotland from April 2025.</p>

            <div className="postcode-form highlighter-rouge" data-form="error-more-detail" >
                <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
                    <div className={`ds_question ${errors.postcodeInput || errors.selectInput ? "ds_question--error" : ""}`}>
                        <h2>{ hideTextInput ? ("Your postcode"): "Postcode"}</h2>
                        { hideTextInput &&  (
                            <label className="ds_label ds_input--error"  htmlFor="postcode-value">
                                You need to enter a full postcode. For Example 'EH11 3DG'
                            </label>)}
                        {errors.postcodeInput && <p className="ds_question__error-message">Please enter a valid postcode</p>}
                        { hideTextInput ? (
                            <input
                                className={`ds_input ds_input--fixed-10 ${errors.postcodeInput ? "ds_input--error" : ""}`}
                                type="text"
                                aria-invalid aria-required aria-describedby="postcode-input"
                                id="postcode-value"
                                {...register("postcodeInput",
                                    {
                                        required: "Please enter a valid Postcode ",
                                        pattern: /^(\s?[A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})/,
                                        maxLength: 9,
                                    }
                                )}
                            />
                            ) : <ChangePostcode isPCPage={true} thePostcode={PassedPostcode}/>
                        }
                        { showSelect && (
                            <div className="split-postcode-select" >
                                <div className="select-address-pc">
                                    {errors.selectInput && <p className="ds_question__error-message">Please enter a valid postcode</p>}
                                    <div className="ds_select-wrapper">
                                        <select className="ds_select" id="component"  {...register("selectInput",{required: true})}>
                                            <option value="">Select an address</option>
                                            {
                                                listAddressList.map((subarray, index) =>{
                                                    return <option key={index} value={subarray}>{subarray.replace(/[\s,]+/g,' ').trim()}</option>
                                                })
                                            }
                                        </select>
                                        <span className="ds_select-arrow" aria-hidden="true"></span>
                                    </div>
                                </div>
                            </div>
                        )}
                        <p>
                            <button className="ds_button ds_no-margin--top" type="submit" >{ hideTextInput ? ("Find"): "Continue"}</button>
                        </p>
                        </div>
                        { hideTextInput ? (
                            <a href="https://www.royalmail.com/find-a-postcode" target="_blank">Don't know your postcode? Get it on Royal Mail (opens in a new tab)</a>
                            ): <Link to={`/ineligible`} onClick={splitIneligible} >My address is not on this list</Link>
                        }
                    </form>
                 </div>
                 
        </>
    );

}

export default PadpPostcodeCheck;

